import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter,NavLink } from "react-router-dom"
import { withTranslation } from 'react-i18next'
import Store from "../../sotres";
import { isMobile } from 'react-device-detect'
import { Input,Dropdown,Menu } from 'antd'

const emitter = Store.emitter
const dispatcher = Store.dispatcher
const store = Store.store

class Footer extends Component {
    state = {
        emailInput: '',
        tishiShow: false,
    }
    
    render() {
        const { t, i18n } = this.props;
        const { emailInput, tishiShow } = this.state
        return (
            <Root>
                <HeaderpcConter>
                    <Headerpc>
                        <FooterFlex>
                            <FooterLeft>
                                <FooterLftitle>{t('title_homeStay1')}</FooterLftitle>
                                <FooterLfdes>{t('title_homeStay2')}</FooterLfdes>
                                <FooterInput>
                                    <InputContent placeholder='Enter your email' value={emailInput} onChange={this.onEmailChange.bind(this, "")} />
                                    <FooterSubmit onClick={this.submit}>SUBSCRIBE</FooterSubmit>
                                </FooterInput>
                                <FooterTishi style={{ display: tishiShow ? 'block' : 'none' }}>{t('title_homeSending')}</FooterTishi>
                            </FooterLeft>
                            <FooterRight>
                                <FooterRititle>{t('title_homeWell')}</FooterRititle>
                                <FooterIco>
                                    <img src={require('../../assets/footer1.png').default} onClick={ () => this.openClick('https://twitter.com/BXH_Blockchain') }/>
                                    {/* <img src={require('../../assets/footer2.png').default}/> */}
                                    <img src={require('../../assets/footer3.png').default} onClick={ () => this.openClick('https://t.me/bxh_global') }/>
                                    {/* <img src={require('../../assets/footer4.png').default} onClick={ () => this.openClick('https://t.me/BXH_Announcement') }/> */}
                                    {/* <img src={require('../../assets/footer5.png').default} onClick={ () => this.openClick('http://medium.com/@bxh-blockchain') }/> */}
                                    <img src={require('../../assets/footer6.png').default} onClick={ () => this.openClick('https://discord.com/invite/SFNGFPQZSv') }/>
                                </FooterIco>
                            </FooterRight>
                        </FooterFlex>
                    </Headerpc>
                </HeaderpcConter>
                <HeaderAllConter>
                    <HeaderAllpc>© 2022. All rights reserved by BXH</HeaderAllpc>
                </HeaderAllConter>
            </Root>
        )
    }


    onEmailChange = (value, event) => {
        var val = event.target.value;
        if(val){
            this.setState({
                emailInput: val,
            })
        }else{
            this.setState({
                emailInput: '',
            })
        }
    }

    submit = () => {
        const { emailInput } = this.state
        if(emailInput){
            store._getBxhUserEmail(emailInput, (data)=>{
                if(data.data.success === true){
                    this.setState({ tishiShow: true })
                    // setTimeout(this.iTimer, 0);
                }
            })
        }
    }

    timer = null;
    iTimer = () => {
        this.timer = setTimeout(() => {
            this.setState({ tishiShow: false })
        }, 4000);
    }

    openClick = (link) => {
        window.open(link);
    }

}

const Root = styled.div`
`
const HeaderpcConter = styled.div`
    width: 100%;
    background: #27142a;
    border-bottom: 1px solid #636770;
`
const Headerpc = styled.div`
    width: 70%;
    margin: auto;
    min-width: var(--page-min-width);
    padding: 50px 0px 30px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        width: 90%;
        min-width: auto;
    };
`
const HeaderAllConter = styled.div`
    width: 100%;
    background: #27142a;
`
const HeaderAllpc = styled.div`
    width: 70%;
    margin: auto;
    min-width: var(--page-min-width);
    font-family: "Roboto";
    padding: 8px 0;
    opacity: .8;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        width: 90%;
        min-width: auto;
    };
`
const FooterFlex = styled.div`
    display: flex;
    justify-content: space-between; 
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        display: block;
    };
`
const FooterLeft = styled.div`
`
const FooterLftitle = styled.div`
    font-family: "Poppins";
    font-weight: bold;
    text-transform: capitalize;
    font-size: 22px;
    line-height: 25px;
    padding-bottom: 10px;
    opacity: 0.8;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        font-size: 18px;
        padding-bottom: 5px;
    };
`
const FooterLfdes = styled.div`
    font-family: "Roboto";
    color: #F6F6F6;
    opacity: 0.8;
    padding-bottom: 20px;
    letter-spacing: 1.5px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        font-size: 12px;
        padding-bottom: 10px;
    };
`
const FooterInput = styled.div`
    display: flex;
    background: #402c45;
    padding: 3px;
    border-radius: 12px;
`
const InputContent = styled(Input)`
    height: 36px;
    background: #402c45;
    border: none;
    margin: 2px 10px;
    font-family: "Roboto";
    color: #fff;
    opacity: 0.4;
    &:hover {
        border-color: #402c45;
        border-right-width: 0px !important;
    }
    &:focus {
        box-shadow: 0 0 0 2px rgba(253, 205, 111, 0);
    };
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        height: 30px;
    };
`
const FooterSubmit = styled.div`
    height: 40px;
    line-height: 40px;
    background: linear-gradient(54.3deg, #BC21BF -6.5%, #6A27D7 99.93%);
    border-radius: 12px;
    padding: 0 10px;
    font-size: 18px;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
        opacity: .8;
    };
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        height: 35px;
        line-height: 35px;
        font-size: 13px;
    };
`
const FooterRight = styled.div`
    width: 30%;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        width: 100%;
    };
`
const FooterRititle = styled.div`
    font-family: "Roboto";
    color: #F6F6F6;
    opacity: 0.8;
    font-size: 16px;
    margin-bottom: 20px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        font-size: 12px;
        margin-top: 20px;
        margin-bottom: 10px;
    };
`
const FooterIco = styled.div`
    img{
        width: 30px;
        margin-right: 15px;
        cursor: pointer;
    }
`
const FooterTishi = styled.div`
    font-family: "Roboto";
    letter-spacing: 1px;
    font-size: 12px;
    color: #BC21BF;
`


export default withTranslation()(withRouter(Footer));
