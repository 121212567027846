import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from "react-router-dom"
import { withTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import Store from '../../sotres'

const emitter = Store.emitter;

class LanguageButton extends Component {
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
    }
    state = {
        MLanuShow: false,
    };
    change = (language)=>{
        const {i18n} = this.props;
        this.setState({ MLanuShow: false })
        if(('changeLanguage' in i18n)&&i18n.language!==language){
            i18n.changeLanguage(language)
        }
    }
    render() {
        const {i18n} = this.props;
        const { MLanuShow } = this.state
        return (
            <Root>
                {
                    !isMobile?
                    <LanuPer>
                        <LanuConter>
                            {
                                i18n.language==='en' ?
                                <div>EN</div>
                                :
                                <div>ZH</div>
                            }
                        </LanuConter>
                        <Modal className='modal' ref={this.modalRef}>
                            <ModalContent>
                                <Row onClick={()=>this.change('en')}>English</Row>
                                <Row onClick={()=>this.change('zh_HK')}>繁體中文</Row>
                            </ModalContent>
                        </Modal>
                    </LanuPer>
                    :
                    // 移动端
                    <LanuPer>
                        <LanuConter onClick={this.MLanu}>
                            {
                                i18n.language==='en' ?
                                <div>EN</div>
                                :
                                <div>ZH</div>
                            }
                        </LanuConter>
                        {
                            MLanuShow?
                            <Modal style={{ display: 'block' }}>
                                <ModalContent>
                                    <Row onClick={()=>this.change('en')}>English</Row>
                                    <Row onClick={()=>this.change('zh_HK')}>繁體中文</Row>
                                </ModalContent>
                            </Modal>
                            :
                            null
                        }
                    </LanuPer>
                }
                
                {
                    isMobile?
                    <LanuApp onClick={this.BXHMClick}>LAUNCH APP</LanuApp>
                    :
                    <LanuApp onClick={this.BXHClick}>LAUNCH APP</LanuApp>
                }
            </Root>
        )
    }

    MLanu = () => {
        const { MLanuShow } = this.state
        this.setState({ MLanuShow: !MLanuShow })
    }

    BXHClick = () => {
        const {i18n} = this.props;
        window.open("https://app.bxh.com/#/");
        // if(i18n.language==='en'){
        //     window.open("https://app.bxh.com/#/?lang=en");
        // }else{
        //     window.open("https://app.bxh.com/#/?lang=zh-HK");
        // }
    }

    BXHMClick = () => {
        const {i18n} = this.props;
        window.location.href = "https://app.bxh.com/#/"; 
        // if(i18n.language==='en'){
        //     window.location.href = "https://app.bxh.com/#/?lang=en"; 
        // }else{
        //     window.location.href = "https://app.bxh.com/#/?lang=zh-HK"; 
        // }
    }

}

const Root = styled.div`
    display: flex;
`
const LanuPer = styled.div`
    position: relative;
    cursor: pointer;
    &:hover {
        .modal {
            display: block;
        }
    }
`
const LanuConter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 28px;
    width: 83px;
    border: 2px solid #C004DE;
    border-radius: 8px;
    &:hover {
        opacity: .8;
    };
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        font-size: 12px;
        padding: 4px 28px;
        margin-top: 3px;
        width: 70px;
    };
`
const Modal = styled.div`
    position: absolute;
    display: none;
    top: 40px;
    right: 0;
    z-index: 100;
    ${({theme})=>theme.mediaQueries.sm} {
        top: 30px;
    };
`
const ModalContent = styled.div`
    margin-top: 0;
    padding: 5px 0;
    background: ${({theme})=>theme.colors.backgroundDisabled};
    width: 100px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    ${({theme})=>theme.mediaQueries.sm} {
        margin-top: 15px;
    };
`
const Row = styled.div`
    padding: 5px 15px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
        background: #181818;
    };
`
const LanuApp = styled.div`
    background: linear-gradient(54.3deg, #BC21BF -6.5%, #6A27D7 99.93%);
    border-radius: 8px;
    height: 36px;
    line-height: 36px;
    padding: 0px 25px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-left: 8px;
    cursor: pointer;
    &:hover {
        opacity: .8;
    };
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        font-size: 12px;
        height: 30px;
        line-height: 30px;
        letter-spacing: 0px;
        margin-top: 3px;
        padding: 0px 15px;
    };
`


export default withTranslation()(withRouter(LanguageButton));
