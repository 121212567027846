import axios from 'axios';
import cookie from 'react-cookies'
import Web3 from 'web3';
import i18n from '../i18n'

const Dispatcher = require('flux').Dispatcher;
const Emitter = require('events').EventEmitter;
  
const dispatcher = new Dispatcher();
const emitter = new Emitter();

class Store {

  // 获取所有链的TVL
  _getBxhTvlTotal = async (callback) => {
    axios({
      // url: 'http://new_test.bxh.com/bxh/api/bxhinfo/chain/tvlTotal',
      // url: 'https://test-app.bxh.com/bxh/api/bxhinfo/chain/tvlTotal',
      url: 'https://api.bxh.com/bxh/api/bxhinfo/chain/tvlTotal',
      method: 'post',
      data: {},
    }).then(function (data) {
      callback(data);
    }).catch(function (error) {
      console.log(error);
    });
  }

  // 提交email
  _getBxhUserEmail = async (emailInput, callback) => {
    axios({
      // url: 'http://new_test.bxh.com/bxh/api/bxhuseremail/send',
      // url: 'https://test-app.bxh.com/bxh/api/bxhuseremail/send',
      url: 'https://api.bxh.com/bxh/api/bxhuseremail/send',
      method: 'post',
      data: {
        email: emailInput,
      },
    }).then(function (data) {
      callback(data);
    }).catch(function (error) {
      console.log(error);
    });
  }

  
}

var store = new Store();
  
export default {
  store: store,
  dispatcher: dispatcher,
  emitter: emitter
};
