import React, { Component } from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { withRouter,NavLink } from "react-router-dom"
import { withTranslation } from 'react-i18next'
import { message } from 'antd';
import Store from '../../sotres'
import { scrollToAnchor } from '../../utils/'
import LanguageButton from './languageButton'
import BG_000 from '../../assets/BG_000.jpg'

const store = Store.store;
const emitter = Store.emitter;

class Nav extends Component {
    state = {
        PCScrolled: false,
        MScrolled: false,
    };

    //在componentDidMount，进行scroll事件的注册，绑定一个函数，让这个函数进行监听处理
    componentDidMount() {
        window.addEventListener('scroll', this.bindHandleScroll)
    }

    //在componentWillUnmount，进行scroll事件的注销
    componentWillUnmount() {
        window.removeEventListener('scroll', this.bindHandleScroll);
    }

    bindHandleScroll = (event) => {
        //滚动的高度
        const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0);
        this.setState({
            PCScrolled: scrollTop > 120,
            MScrolled: scrollTop > 30
        })
    }

    BXHClick = () => {
        const {i18n} = this.props;
        window.open("https://app.bxh.com/#/");
        // if(i18n.language==='en'){
        //     window.open("https://app.bxh.com/#/?lang=en");
        // }else{
        //     window.open("https://app.bxh.com/#/?lang=zh-HK");
        // }
    }

    render() {
        const { PCScrolled, MScrolled } = this.state
        return (
            <Root style={{ position: !isMobile ? 'fixed' : 'sticky',  backgroundColor: !isMobile ? '' : '#27142a' }}>
                {
                    !isMobile&&PCScrolled?
                    <ContentBg>
                        <img src={require('../../assets/BG_000.jpg').default}/>
                    </ContentBg>
                    :
                    null
                }

                {/* {
                    isMobile&&MScrolled?
                    <ContentBg>
                        <img src={require('../../assets/BG_000.jpg').default}/>
                    </ContentBg>
                    :
                    null
                } */}
                
                <Content>
                    <Left>
                        <LogoBtn onClick={()=>this.scrollClick('Home')}>
                            <Logo src='https://bxh-images.s3.ap-east-1.amazonaws.com/images/logo-bsc.png'/>
                        </LogoBtn>
                        <NavDefi>
                            <em onClick={this.messageClick}>Exchange</em>&nbsp;&nbsp;
                            <span onClick={this.BXHClick}>DEFI</span>
                        </NavDefi>
                        {/* <NavDefi>
                            <span>Exchange</span>&nbsp;&nbsp;<em>DEFI</em>
                        </NavDefi> */}
                        {this.renderHomeLeftNav()}
                    </Left>
                    <Right>
                        {this.renderOtherRightNav()}
                    </Right>
                </Content>
            </Root>
        )
    }
    renderHomeLeftNav = ()=>{
        return (
            <LeftBtn>
                <span onClick={()=>this.scrollClick('Benefit')}>Benefit</span>
                <span onClick={()=>this.scrollClick('Services')}>Services</span>
                <span onClick={()=>this.scrollClick('Partners')}>Partners</span>
                <span onClick={()=>this.scrollClick('Team')}>Team</span>
                <span onClick={()=>this.scrollClick('Contact')}>Contact</span>
            </LeftBtn>
        )
    }

    renderOtherRightNav = ()=>{
        return (
            <>
                <LanguageButton/>
            </>
        )
    }

    scrollClick = (name) => {
        scrollToAnchor(name)
    }

    messageClick = () => {
        // message.warning('Coming Soon');
        window.open("https://ex.bxh.com/");
    }
}

const Root = styled.div`
// position: sticky;
position: fixed;
top: 0;
z-index: 999;
width: 100%;
height: 52px;
display: flex;
justify-content: center;
align-items: center;
// background: url(${BG_000}) no-repeat;
// background-size: 100% 100%;
z-index: 9999;
${({ theme }) => theme.mediaQueries.sm} {
    height: 80px;
    min-width: var(--page-min-width);
};
`
const ContentBg = styled.div`
position: fixed;
width: 100%;
height: 80px;
img{
    width: 100%;
    height: 80px;
}
`
const Content = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 70%;
height: 100%;
min-width: var(--page-min-width);
z-index: 99999;
${({ theme }) => theme.mediaMaxQueries.xs} {
    width: 90%;
    min-width: auto;
};
`
const Left = styled.div`
display: flex;
align-items: center;
gap: 30px;
`
const Right = styled.div`
`
const LogoBtn = styled.div`
flex-shrink: 0;
cursor: pointer;
`
const Logo = styled.img`
width: 48px;
${({ theme }) => theme.mediaQueries.sm} {
    width: 84px;
};
`
const LeftBtn = styled.div`
display: none;
align-items: center;
gap: 5px;
margin-left: 10%;
span{
    font-family: 'Poppins';
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #F6F6F6;
    padding: 0 15px;
    &:hover {
        color: rgba(192, 4, 222, .8);
    };
}
${({ theme }) => theme.mediaQueries.sm} {
    display: flex;
};
`
const NavDefi = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    background: linear-gradient(54.3deg, #BC21BF -6.5%, #6A27D7 99.93%);
    border-radius: 8px;
    padding: 5px;
    cursor: pointer;
    em{
        font-style: inherit;
    }
    span{
        background: #000000;
        border-radius: 8px;
        padding: 4px 10px;
    }
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        display: none;
    };
`


export default withTranslation()(withRouter(Nav));
