//滚动
export function scrollToAnchor(anchorName) {
  if (anchorName) {
      // 找到锚点
      const anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if(anchorElement) { anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'}); }
  }
}

export const getDateDiff = (dateTimeStamp) => {
  var result = ''
  var minute = 1000 * 60
  var hour = minute * 60
  var day = hour * 24
  var month = day * 30
  var now = new Date().getTime()
  var diffValue = now - dateTimeStamp
  if (diffValue < 0) return
  var monthC = diffValue / month
  var weekC = diffValue / (7 * day)
  var dayC = diffValue / day
  var hourC = diffValue / hour
  var minC = diffValue / minute
  if (monthC >= 1) {
    result = "" + parseInt(monthC) + " month ago"
  }
  else if (weekC>=1) {
    result = "" + parseInt(weekC) + " week ago"
  }
  else if (dayC >= 1) {
    result = ""+ parseInt(dayC) + " day ago"
  }
  else if (hourC >= 1){
    result = "" + parseInt(hourC) + " hour ago"
  }
  else if (minC >= 1) {
    result = ""+ parseInt(minC) + " minutes ago"
  } else {
    result = "In one minute"
  }
  return result
}