import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    img {
      height: auto;
      max-width: 100%;
    }
  }

  ul {
    list-style: none; 
  }

  li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  // li::before {
  //   content: "•";
  //   color: ${({ theme }) => theme.colors.text};
  //   margin-right: 8px;
  // }

  /* Scrollbar */
  ::-webkit-scrollbar {
    // width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.text}; 
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${({ theme }) => theme.colors.input}; 
    border-radius: 10px;
  }

  .ant-message{
    z-index: 9999999;
  }
  .ant-message-notice-content{
    background: linear-gradient(54.3deg,#BC21BF -6.5%,#6A27D7 99.93%);
    border-radius: 12px;
    color: #fff;
    opacity: .8;
  }
`

export default GlobalStyle
