import React, { Component } from 'react'
import ReactWOW from '../../utils/ReactWOW'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { NavLink } from "react-router-dom"
import { message } from 'antd';
import Store from "../../sotres";
import Player from 'griffith'
import BG_001 from '../../assets/BG_001.jpg'
import BG_002 from '../../assets/BG_002.jpg'
import BG_003 from '../../assets/BG_003.jpg'


const emitter = Store.emitter
const dispatcher = Store.dispatcher
const store = Store.store

class Home extends Component {
    state = {
        auoutAbout1: true,
        auoutAbout2: false,
        popShow: true,
        TvlArray: [],
        TvlTotal: '--',
    }

    componentWillMount() {
        this.refreshData();
    }

    componentDidMount(){
        document.documentElement.scrollTop = document.body.scrollTop = 0;
    }

    // 刷新数据
    refreshData = () => {
        store._getBxhTvlTotal((data)=>{
            if(data.data.success===true){
                this.setState({
                    TvlArray: data.data.body,
                    TvlTotal: data.data.body.ETH + data.data.body.HECO + data.data.body.BSC + data.data.body.OKT + data.data.body.AVAX
                })
            }
        })
    }

    BXHClick = () => {
        const {i18n} = this.props;
        window.open("https://app.bxh.com/#/");
        // if(i18n.language==='en'){
        //     window.open("https://app.bxh.com/#/?lang=en");
        // }else{
        //     window.open("https://app.bxh.com/#/?lang=zh-HK");
        // }
    }

    BXHMClick = () => {
        const {i18n} = this.props;
        window.location.href = "https://app.bxh.com/#/"; 
        // if(i18n.language==='en'){
        //     window.location.href = "https://app.bxh.com/#/?lang=en"; 
        // }else{
        //     window.location.href = "https://app.bxh.com/#/?lang=zh-HK"; 
        // }
    }

    aboutVideo1 = () => {
        this.setState({
            auoutAbout1: false,
            auoutAbout2: true,
        })
    }

    render() {
        const {t} = this.props;
        return (
            <Root>
                {this.renderNav()}
                {this.renderInvestors()}
                {/* 弹窗 */}
                {/* {this.renderPop()} */}
            </Root>
        )
    }

    renderNav = ()=>{
        const {t} = this.props;
        const { TvlArray, TvlTotal } = this.state
        return (
            <OneBgConter id="Home">
                <BannerVideo src="https://bxh-images.s3.ap-east-1.amazonaws.com/images/sapce.mp4"
                    autoPlay="autoPlay"
                    loop="loop"
                    muted="muted"
                />
                <OneConter>
                    <OneTitle>
                        BXH - <span>ONE-STOP FINANCIAL</span>
                    </OneTitle>
                    <OneTitle>
                        services platform for
                    </OneTitle>
                    <OneTitle>
                        Web3 and Metaverse
                    </OneTitle>
                    {
                        isMobile?
                        <OneConterApp>
                            <OneApp onClick={this.messageClick}>EXCHANGE</OneApp>
                            <OneApp style={{marginLeft: '20px'}} onClick={this.messageAppClick}>APP DOWNLOAD</OneApp>
                        </OneConterApp>
                        :
                        <OneConterApp>
                            <OneApp style={{width:'170px'}} onClick={this.messageClick}>EXCHANGE</OneApp>
                            <OneApp style={{marginLeft: '20px',fontFamily: 'Inter',fontWeight: '400'}} onClick={this.messageAppClick}>APP DOWNLOAD</OneApp>
                        </OneConterApp>
                    }
                    <TvlConter>
                        <OneApp style={{width:'170px'}} onClick={this.BXHMClick}>LAUNCH APP</OneApp>
                        <OneTotal style={{marginLeft: '0px'}}>TOTAL TVL: &nbsp;
                            {
                                TvlTotal&&TvlTotal!=='--'?
                                <i>${TvlTotal.toFixed(2)}</i>
                                :
                                <i>--</i>
                            }
                        </OneTotal>
                        {/* <div>
                            <span>ETH TVL</span>
                            {
                                TvlArray&&TvlArray.ETH?
                                <em>${TvlArray.ETH.toFixed(2)}</em>
                                :
                                <em>--</em>
                            }
                        </div>
                        <div>
                            <span>HECO TVL</span>
                            {
                                TvlArray&&TvlArray.HECO?
                                <em>${TvlArray.HECO.toFixed(2)}</em>
                                :
                                <em>--</em>
                            }
                        </div>
                        <div>
                            <span>BSC TVL</span>
                            {
                                TvlArray&&TvlArray.BSC?
                                <em>${TvlArray.BSC.toFixed(2)}</em>
                                :
                                <em>--</em>
                            }
                        </div>
                        <div>
                            <span>OEC TVL</span>
                            {
                                TvlArray&&TvlArray.OKT?
                                <em>${TvlArray.OKT.toFixed(2)}</em>
                                :
                                <em>--</em>
                            }
                        </div>
                        <div style={{borderRight:'0px'}}>
                            <span>AVAX TVL</span>
                            {
                                TvlArray&&TvlArray.AVAX?
                                <em>${TvlArray.AVAX.toFixed(2)}</em>
                                :
                                <em>--</em>
                            }
                        </div> */}
                    </TvlConter>
                </OneConter>   
            </OneBgConter>
        )
    }
    
    renderInvestors = ()=>{
        const {t, i18n} = this.props;
        const { TvlArray, TvlTotal } = this.state
        return (
            <ThreeBgConter>
                <HomeConter>
                    {/* {
                        isMobile?
                        <div>
                            <TvlConter>
                                <div style={{width:'33%',padding: '0 5px',paddingLeft:'0px'}}>
                                    <span>TOTAL TVL</span>
                                    {
                                        TvlTotal&&TvlTotal!=='--'?
                                        <em>${TvlTotal.toFixed(2)}</em>
                                        :
                                        <em>--</em>
                                    }
                                </div>
                                <div style={{width:'33%',padding: '0 15px'}}>
                                    <span>ETH TVL</span>
                                    {
                                        TvlArray&&TvlArray.ETH?
                                        <em>${TvlArray.ETH.toFixed(2)}</em>
                                        :
                                        <em>--</em>
                                    }
                                </div>
                                <div style={{width:'33%',padding: '0 15px',borderRight:'0px'}}>
                                    <span>HECO TVL</span>
                                    {
                                        TvlArray&&TvlArray.HECO?
                                        <em>${TvlArray.HECO.toFixed(2)}</em>
                                        :
                                        <em>--</em>
                                    }
                                </div>
                            </TvlConter>
                            <TvlConter>
                                <div style={{width:'33%',padding: '0 5px',paddingLeft:'0px'}}>
                                    <span>BSC TVL</span>
                                    {
                                        TvlArray&&TvlArray.BSC?
                                        <em>${TvlArray.BSC.toFixed(2)}</em>
                                        :
                                        <em>--</em>
                                    }
                                </div>
                                <div style={{width:'33%',padding: '0 15px'}}>
                                    <span>OEC TVL</span>
                                    {
                                        TvlArray&&TvlArray.OKT?
                                        <em>${TvlArray.OKT.toFixed(2)}</em>
                                        :
                                        <em>--</em>
                                    }
                                </div>
                                <div style={{width:'33%',padding: '0 15px',borderRight:'0px'}}>
                                    <span>AVAX TVL</span>
                                    {
                                        TvlArray&&TvlArray.AVAX?
                                        <em>${TvlArray.AVAX.toFixed(2)}</em>
                                        :
                                        <em>--</em>
                                    }
                                </div>
                            </TvlConter>
                        </div>
                        :
                        null
                    } */}

                    <ScrollHeight id="Benefit"></ScrollHeight>
                    <InvestorsTitle1>{t('title_homeInvestors')}</InvestorsTitle1>
                    <InvestorsDes>
                        <InvestorsTitle2>
                            <Provides>
                                <SingleLeft>
                                    <img src={require('../../assets/pro1.png').default}/>
                                    <SingleDashed></SingleDashed>
                                </SingleLeft>
                                <span>{t('title_homePortfolio')}</span>
                            </Provides>
                            <Provides>
                                <SingleLeft>
                                    <img src={require('../../assets/pro2.png').default}/>
                                    <SingleDashed></SingleDashed>
                                </SingleLeft>
                                <span>{t('title_homeDefi')}</span>
                            </Provides>
                            <Provides>
                                <SingleLeft>
                                    <img src={require('../../assets/pro1.png').default}/>
                                </SingleLeft>
                                <span>{t('title_homeCost')}</span>
                            </Provides>
                        </InvestorsTitle2>
                        <InvestorsImg>
                            <img src={require('../../assets/bxh_on1.png').default}/>
                        </InvestorsImg>
                    </InvestorsDes>

                    
                    <ScrollHeight id="Services"></ScrollHeight>
                    <ServicesConter>
                        <ServicesTitle>{t('title_homeServices')}</ServicesTitle>
                        <img src={require('../../assets/Rectangle.png').default} />
                    </ServicesConter>


                    <ScrollHeight id="Partners"></ScrollHeight>
                    <FarmingConter>
                        <SonTitle>{t('title_homeFarming')}</SonTitle>
                        <FontConter>
                            <FontLeft>
                                <img src={require('../../assets/Vector1.png').default}/>
                                <FontSize>
                                    <span>{t('title_homePowered1')}</span>
                                    <span>{t('title_homePowered2')}</span>
                                    <FramBottom onClick={this.messageClick}>Enter Now</FramBottom>
                                </FontSize>
                            </FontLeft>
                            <FontRight>
                                <div></div>
                                <img src={require('../../assets/f1.png').default}/>
                            </FontRight>
                        </FontConter>
                    </FarmingConter>

                    <ScrollHeight></ScrollHeight>
                    <FarmingConter >
                        <SonTitle>{t('title_homeBridge')}</SonTitle>
                        <FontConter style={{marginTop:isMobile?'20px':'40px'}}>
                            <FramRight>
                                <div></div>
                                <img src={require('../../assets/fram4.png').default}/>
                            </FramRight>
                            <FramLeft>
                                <img src={require('../../assets/Vector2.png').default}/>
                                <FontSize>
                                    <span>{t('title_homeBridge1')}</span>
                                    <span>{t('title_homeBridge2')}</span>
                                    <FramChain>
                                        {t('title_homeBridgeChain')}：HECO BSC AVAX OEC
                                    </FramChain>
                                    <FramBottom onClick={this.messageClick}>Enter Now</FramBottom>
                                </FontSize>
                            </FramLeft>
                        </FontConter>
                    </FarmingConter>

                    <ScrollHeight></ScrollHeight>
                    <FarmingConter >
                        <SonTitle>{t('title_homeExchange')}</SonTitle>
                        <FontConter>
                            <FramLeft style={{marginLeft:'0px',marginTop:'5%'}}>
                                <img src={require('../../assets/Vector3.png').default}/>
                                <FontSize>
                                    <span>{t('title_homeExchange1')}</span>
                                    <span>{t('title_homeExchange2')}</span>
                                    <FramBottom onClick={this.messageClick}>Enter Now</FramBottom>
                                </FontSize>
                            </FramLeft>
                            <FramRight style={{left:'60%'}}>
                                <div></div>
                                <img src={require('../../assets/fram2.png').default} style={{left:'0px'}} />
                            </FramRight>
                        </FontConter>
                    </FarmingConter>

                    
                    <ScrollHeight></ScrollHeight>
                    <ServicesConter style={{marginTop:isMobile?'60px':'0px'}}>
                        <ServicesContPool>
                            <ServicesPool style={{paddingBottom:isMobile?'20px':'40px' }}>{t('title_homeWhat')}</ServicesPool>
                        </ServicesContPool>
                        <AboutLent>
                            <span style={{paddingBottom:isMobile?'10px':'20px'}}>{t('title_homeStaking')}</span>
                            <span>{t('title_homeClients1')}</span>
                        </AboutLent>
                    </ServicesConter>
                    
                    <ScrollHeight id="Team"></ScrollHeight>
                    <ServicesConter style={{marginTop:isMobile?'60px':'0px'}}>
                        <ServicesContPool>
                            <ServicesPool style={{paddingBottom:'40px' }}>{t('title_homeOurTeam')}</ServicesPool>
                        </ServicesContPool>
                        <OurConter>
                            <OurPro>
                                <OurImg src={require('../../assets/our1.png').default} />
                                <span>Neo Wang</span>
                            </OurPro>
                            <OurPro>
                                <OurImg src={require('../../assets/our2.png').default} />
                                <span>Bashir</span>
                            </OurPro>
                            <OurPro>
                                <OurImg src={require('../../assets/our3.png').default} />
                                <span>Edward ng</span>
                            </OurPro>
                        </OurConter>
                    </ServicesConter>
                    
                    <WallConter>
                        <JoinTiCont>
                            <JoinTitle>BXH Wallet</JoinTitle>
                        </JoinTiCont>
                        <WallTitle>XUSD</WallTitle>
                        <WallDes>100% custodial stable coin</WallDes>
                        <WallList>
                            <WallOnt>
                                <img src={require('../../assets/wall1.png').default}/>
                                <span>D-Wallet</span>
                            </WallOnt>
                            <WallOnt style={{ width: isMobile? '100%': '25%' }}>
                                <img src={require('../../assets/wall2.png').default}/>
                                <span>Payment gateway</span>
                            </WallOnt>
                            <WallOnt>
                                <img src={require('../../assets/wall3.png').default}/>
                                <span>OTC</span>
                            </WallOnt>
                        </WallList>
                        <WallBXHImg>
                            <img src={require('../../assets/bxh_2.png').default}/>
                        </WallBXHImg>
                    </WallConter>

                    <PartnersConter>
                        <PartnersTiCont>
                            <PartnersTitle>{t('title_homePartners')}</PartnersTitle>
                        </PartnersTiCont>
                        <PartnersImg>
                            <span>
                                <img src={require('../../assets/Partners1.png').default}/>
                            </span>
                            <span>
                                <img src={require('../../assets/Partners2.png').default}/>
                            </span>
                            <span>
                                <img src={require('../../assets/Partners3.png').default}/>
                            </span>
                            <span>
                                <img src={require('../../assets/Partners4.png').default}/>
                            </span>
                            <span>
                                <img src={require('../../assets/Partners5.png').default}/>
                            </span>
                            <span>
                                <img src={require('../../assets/Partners6.png').default}/>
                            </span>
                            <span>
                                <img src={require('../../assets/Partners7.png').default}/>
                            </span>
                            <span>
                                <img src={require('../../assets/Partners8.png').default}/>
                            </span>
                            <span>
                                <img src={require('../../assets/Partners9.png').default}/>
                            </span>
                            <span>
                                <img src={require('../../assets/Partners10.png').default}/>
                            </span>
                        </PartnersImg>
                    </PartnersConter>

                    <ScrollHeight id="Contact"></ScrollHeight>
                    <PartnersConter>
                        <JoinTiCont>
                            <JoinTitle>{t('title_homeJoin')}</JoinTitle>
                        </JoinTiCont>
                        <JoinDes>{t('title_homeJoin1')}</JoinDes>
                        <JoinCommit>
                            <span onClick={ () => this.openClick('https://discord.com/invite/SFNGFPQZSv') }>{t('title_homeJoin2')}</span>
                        </JoinCommit>
                    </PartnersConter>

                </HomeConter>
            </ThreeBgConter>
        )
    }

    renderPop = ()=>{
        const { popShow } = this.state
        return (
            <PopConter style={{ display: popShow ? 'block' : 'none' }}>
                <img src="https://bxh-images.s3.ap-east-1.amazonaws.com/images/2022-05-19+20.24.00.jpg" onClick={ () => this.popClick('') }/>
                <PopClose onClick={this.popClose}></PopClose>
            </PopConter>
        )
    }


    openClick = (link) => {
        window.open(link);
    }

    popClick = (link) => {
        // window.open(link);
        this.setState({ popShow: false })
    }
    popClose = () => {
        this.setState({ popShow: false })
    }

    messageClick = () => {
        window.open("https://ex.bxh.com/");
    }

    messageAppClick = () => {
        window.open("https://ex.bxh.com/m/other/download");
    }

}


const Root = styled.div`
`
const HomeConter = styled.div`
    width: 70%;
    margin: auto;
    min-width: var(--page-min-width);
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        width: 90%;
        min-width: auto;
    };
`
const OneBgConter = styled.div`
    position: relative;
    // background: url(${BG_001}) no-repeat;
    background-size: 100% 100%;
    height: 80vh;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        height: 20rem;
    };
`
const BannerVideo = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
`
const OneConter = styled.div`
    position: absolute;
    top: 0px;
    left: 15%;
    width: 70%;
    margin: auto;
    min-width: var(--page-min-width);
    padding-top: 8%;
    
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        width: 90%;
        left: 5%;
        min-width: auto;
        padding-top: 50px;
    };
`
const OneTitle = styled.div`
    font-size: 44px;
    text-transform: uppercase;
    line-height: 60px;
    span{
        background: linear-gradient(90deg, #F54097 0%, #EF369B 7.69%, #E92C9F 15.38%, #E122A4 23.08%, #D918A9 30.77%, #D00DAE 38.46%, #C603B3 46.15%, #BA00B9 53.85%, #AD01BE 61.54%, #9F07C4 69.23%, #8E10C9 76.92%, #7A18CF 84.62%, #6220D4 92.31%, #3F26D9 100%), linear-gradient(87.21deg, #F54097 0%, #3F26D9 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        font-size: 16px;
        line-height: 25px;
    };
`
const OneConterApp = styled.div`
    display: flex;
    margin-top: 3%;
`
const OneApp = styled.div`
    background: linear-gradient(54.3deg, #BC21BF -6.5%, #6A27D7 99.93%);
    border-radius: 12px;
    display: inline-block;
    line-height: 40px;
    text-align: center;
    font-size: 18px;
    padding: 5px 25px;
    cursor: pointer;
    &:hover {
        opacity: .8;
    };
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        margin-top: 10px;
        font-size: 12px;
        padding: 5px 10px;
        line-height: 20px;
        width: auto !important;
    };
`
const OneTotal = styled.div`
    font-family: 'Inter';
    font-weight: 600;
    background: rgba(192,4,222,.5);
    border-radius: 10px;
    line-height: 40px;
    padding: 5px 15px;
    margin-left: 20px;
    i{
        font-style: inherit;
    }
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        margin-top: 10px;
        margin-left: 0px;
        font-size: 12px;
        padding: 5px 10px;
        line-height: 20px;
    };
`
const TwoBgConter = styled.div`
    background: url(${BG_002}) no-repeat;
    background-size: 100% 100%;
    padding: 100px 0;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        padding: 35px 0;
    };
`
const TwoGetConter = styled.div`
    display: flex;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        display: block;
    };
`
const TwoLeftConter = styled.div`
    width: 48%;
    margin-right: 2%;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        width: 100%;
        margin-right: 0px;
    };
`
const TwoRightConter = styled.div`
    width: 48%;
    margin-left: 2%;
    i{
        display: block;
        font-style: inherit;
        text-transform: uppercase;
        color: #C004DE;
        letter-spacing: 0.2em;
        padding-top: 10px;
        font-size: 18px;
    }
    span{
        display: block;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        font-size: 36px;
        padding: 10px 0;
        line-height: 40px;
    }
    em{
        display: block;
        font-style: inherit;
        font-size: 16px;
        font-family: 'Poppins';
        padding-bottom: 5px;
    }
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        width: 100%;
        margin-left: 0px;
        i{
            padding-top: 10px;
            font-size: 14px;
        }
        span{
            font-size: 20px;
            padding: 5px 0 10px;
        }
        em{
            padding-bottom: 5px;
            font-size: 14px;
            opacity: .8;
        }
    };
`
const AboutVideos = styled.div`
    position: relative;
    cursor: pointer;
`
const Gamesplay = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70px;
    margin-left: -35px;
    margin-top: -35px;
`

const BXHVideo = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
`
const ThreeBgConter = styled.div`
    position: relative;
    background: url(${BG_003}) no-repeat;
    background-size: 100% 100%;
    padding-bottom: 80px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        background: none;
    }
`
const InvestorsTitle1 = styled.div`
    display: inline-block;
    background: linear-gradient(90deg, #F54097 0%, #EF369B 7.69%, #E92C9F 15.38%, #E122A4 23.08%, #D918A9 30.77%, #D00DAE 38.46%, #C603B3 46.15%, #BA00B9 53.85%, #AD01BE 61.54%, #9F07C4 69.23%, #8E10C9 76.92%, #7A18CF 84.62%, #6220D4 92.31%, #3F26D9 100%), linear-gradient(87.21deg, #F54097 0%, #3F26D9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-transform: uppercase;
    font-size: 36px;
    padding-top: 20px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        font-size: 20px;
        line-height: 25px;
        padding-top: 30px;
    };
`
const InvestorsDes = styled.div`
    display: flex;
    position: relative;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        
    };
`
const InvestorsTitle2 = styled.div`
    width: 65%;
    padding-top: 40px;
    span{
        display: block;
        font-family: 'Poppins';
        font-size: 26px;
        padding-bottom: 10px;
        letter-spacing: 1px;
        opacity: .9;
    }
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        width: 100%;
        padding-top: 10px;
        span{
            font-size: 14px;
            opacity: .8;
            padding-bottom: 5px;
        }
    };
`
const InvestorsImg = styled.div`
    position: absolute;
    right: 0px;
    top: 5%;
    width: 40%;
    img{
        width: 100%;
    }
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        display: none;
    };
`
const ScrollHeight = styled.div`
    height: 80px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        height: 0px;
    };
`
const ServicesConter = styled.div`
`
const ServicesTitle = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 40px;
    font-size: 16px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        padding-top: 40px;
        padding-bottom: 20px;
        font-size: 14px;
    };
`
const ServicesContPool = styled.div`
    text-align: center;
`
const ServicesPool = styled.div`
    display: inline-block;
    background: linear-gradient(90deg,#F54097 0%,#EF369B 7.69%,#E92C9F 15.38%,#E122A4 23.08%,#D918A9 30.77%,#D00DAE 38.46%,#C603B3 46.15%,#BA00B9 53.85%,#AD01BE 61.54%,#9F07C4 69.23%,#8E10C9 76.92%,#7A18CF 84.62%,#6220D4 92.31%,#3F26D9 100%),linear-gradient(87.21deg,#F54097 0%,#3F26D9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 30px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 30px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        font-size: 20px;
        padding-top: 0px;
        padding-bottom: 20px;
    };
`
const ServicesPont = styled.div`
    display: flex;
    gap: 20px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        display: block;
        gap: 0px;
    }
`
const ServicesBorder = styled.div`
    width: 20%;
    border: 1px solid #C004DE;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90deg, rgba(255, 255, 255, 0.1) -1.52%, rgba(255, 255, 255, 0.024) 104.35%);
    backdrop-filter: blur(10px);
    border-radius: 12px;
    padding: 10px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        width: 100%;
        margin-bottom: 10px;
    }
`
const ServicesImg = styled.div`
    text-align: center;
    padding: 10px 0;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        padding: 0px 0px 5px;
        img{
            width: 50px;
        }
    };
`
const ServicesFont = styled.div`
    display: flow-root;
    background: linear-gradient(90deg, #F54097 0%, #EF369B 7.69%, #E92C9F 15.38%, #E122A4 23.08%, #D918A9 30.77%, #D00DAE 38.46%, #C603B3 46.15%, #BA00B9 53.85%, #AD01BE 61.54%, #9F07C4 69.23%, #8E10C9 76.92%, #7A18CF 84.62%, #6220D4 92.31%, #3F26D9 100%), linear-gradient(87.21deg, #F54097 0%, #3F26D9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-transform: uppercase;
    text-align: center;
    font-size: 24px;
    line-height: 50px;
    height: 50px;
    margin-bottom: 60px;
    margin-top: 20px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        font-size: 16px;
        height: 30px;
        line-height: 30px;
        margin-bottom: 0px;
        margin-top: 0px;
    };
`
const ServicesSize = styled.div`
    span{
        display: block;
        font-family: 'Poppins';
        line-height: 24px;
        padding-top: 10px;
    }
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        span{
            padding-top: 5px;
            opacity: .8;
        }
    }
`
const GalleryConter = styled.div`
    padding-top: 100px;
`
const GalleryTitle = styled.div`
    letter-spacing: 0.2em;
    text-transform: uppercase;
    padding-bottom: 10px;
`
const GalleryNTF = styled.div`
    display: inline-block;
    background: linear-gradient(90deg, #F54097 0%, #EF369B 7.69%, #E92C9F 15.38%, #E122A4 23.08%, #D918A9 30.77%, #D00DAE 38.46%, #C603B3 46.15%, #BA00B9 53.85%, #AD01BE 61.54%, #9F07C4 69.23%, #8E10C9 76.92%, #7A18CF 84.62%, #6220D4 92.31%, #3F26D9 100%), linear-gradient(87.21deg, #F54097 0%, #3F26D9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 36px;
    margin-bottom: 20px;
`
const GalleryMore = styled.div`
    width: 200px;
    border: 2px solid;
    border-image: linear-gradient(to right, #F54097, #3F26D9) 1;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-size: 16px;
    cursor: pointer;
`
const FarmingConter = styled.div`
    margin-top: 20px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        margin-top: 70px;
    };
`
const SonConter = styled.div`
    display: flex;
    position: relative;
`
const SonLeft = styled.div`
    position: absolute;
    left: -70px;
    width: 25px;
    height: 600px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        left: -15px;
        height: 400px;
    };
`
const SonRight = styled.div`
    width: 100%;
    padding-bottom: 100px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        margin-left: 10px;
        padding-bottom: 40px;
    };
`
const SonDian = styled.div`
    position: absolute;
    top: 0px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 4px solid #C004DE;
    display: inline-block;
    box-sizing: border-box;
    background: #fff;
    z-index: 9;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        width: 15px;
        height: 15px;
    };
`
const SonXian = styled.div`
    position: absolute;
    top: 0px;
    left: 50%;
    margin-left: -2px;
    width: 4px;
    height: 100%;
    background: #C004DE;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        width: 2px;
        margin-left: -6px;
    };
`
const SonTitle = styled.div`
    display: inline-block;
    background: linear-gradient(90deg,#F54097 0%,#EF369B 7.69%,#E92C9F 15.38%,#E122A4 23.08%,#D918A9 30.77%,#D00DAE 38.46%,#C603B3 46.15%,#BA00B9 53.85%,#AD01BE 61.54%,#9F07C4 69.23%,#8E10C9 76.92%,#7A18CF 84.62%,#6220D4 92.31%,#3F26D9 100%),linear-gradient(87.21deg,#F54097 0%,#3F26D9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-letter-spacing: 0.05em;
    -moz-letter-spacing: 0.05em;
    -ms-letter-spacing: 0.05em;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 36px;
    margin-bottom: 20px;
    line-height: 35px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 0px;
    }
`
const SonDes = styled.div`
    position: relative;
    height: 380px;
    border: 1px solid #13B8BA;
    border-radius: 12px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90deg, rgba(255, 255, 255, 0.1) -1.52%, rgba(255, 255, 255, 0.024) 104.35%);
    margin-top: 30px;
    padding: 25px 20px;
    span{
        display: block;
        font-family: 'Poppins';
        // font-family: "Poppins";
        font-size: 24px;
        line-height: 45px;
        padding-bottom: 20px;
        opacity: .9;
        letter-spacing: 1px;
        width: 88%;
    }
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        height: auto;
        margin-top: 10px;
        padding: 15px 20px;
        span{
            font-size: 14px;
            line-height: 25px;
            padding-bottom: 10px;
            opacity: .8;
        }
    }
`
const SonFram1 = styled.img`
    position: absolute;
    height: 440px;
    bottom: -110px;
    right: -180px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        height: 120px;
        right: -30px;
        bottom: -30px;
    };
`
const SonFram2 = styled.img`
    position: absolute;
    height: 440px;
    bottom: -130px;
    right: -180px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        height: 120px;
        right: -40px;
        bottom: -40px;
    };
`
const SonFram3 = styled.img`
    position: absolute;
    height: 350px;
    bottom: -45px;
    right: -130px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        height: 110px;
        right: -30px;
        bottom: -20px;
    };
`
const PartnersConter = styled.div`
    margin-top: 20px;
    border: 1px solid #13B8BA;
    background: rgba(192, 4, 222, 0.1);
    border-radius: 20px;
    padding: 20px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        margin-top: 70px;
    };
`
const PartnersTiCont = styled.div`
    text-align: center;
`
const PartnersTitle = styled.div`
    display: inline-block;
    background: linear-gradient(90deg,#F54097 0%,#EF369B 7.69%,#E92C9F 15.38%,#E122A4 23.08%,#D918A9 30.77%,#D00DAE 38.46%,#C603B3 46.15%,#BA00B9 53.85%,#AD01BE 61.54%,#9F07C4 69.23%,#8E10C9 76.92%,#7A18CF 84.62%,#6220D4 92.31%,#3F26D9 100%),linear-gradient(87.21deg,#F54097 0%,#3F26D9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-align: center;
    text-transform: uppercase;
    font-size: 36px;
    padding: 50px 0;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        padding: 0;
        font-size: 20px;
        margin-bottom: 10px;
    };
`
const PartnersImg = styled.div`
    span{
        display: inline-block;
        width: 20%;
        text-align: center;
        margin-bottom: 50px;
    }
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        span{
            width: 50%;
            margin-bottom: 20px;
        }
        img{
            width: 60%;
        }
    };
`
const JoinTiCont = styled.div`
    text-align: center;
`
const JoinTitle = styled.div`
    display: inline-block;
    background: linear-gradient(90deg,#F54097 0%,#EF369B 7.69%,#E92C9F 15.38%,#E122A4 23.08%,#D918A9 30.77%,#D00DAE 38.46%,#C603B3 46.15%,#BA00B9 53.85%,#AD01BE 61.54%,#9F07C4 69.23%,#8E10C9 76.92%,#7A18CF 84.62%,#6220D4 92.31%,#3F26D9 100%),linear-gradient(87.21deg,#F54097 0%,#3F26D9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-align: center;
    text-transform: uppercase;
    font-size: 36px;
    padding: 20px 0;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        padding: 0;
        font-size: 20px;
        margin-bottom: 10px;
    };
`
const JoinDes = styled.div`
    font-family: "Poppins";
    color: #A09FAF;
    font-size: 22px;
    width: 95%;
    margin: auto;
    text-align: center;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        width: 100%;
        font-size: 14px;
    };
`
const JoinCommit = styled.div`
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
    span{
        display: inline-block;
        background: linear-gradient(54.3deg, #BC21BF -6.5%, #6A27D7 99.93%);
        border-radius: 8px;
        font-size: 16px;
        text-align: center;
        text-transform: capitalize;
        height: 45px;
        line-height: 45px;
        width: 200px;
        cursor: pointer;
    }
    &:hover {
        opacity: .8;
    };
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        margin-bottom: 10px;
        span{
            font-size: 14px;
            height: 40px;
            line-height: 40px;
            width: 170px;
        }
    }
`
const WallConter = styled.div`
    position: relative;
    border: 1px solid #13B8BA;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90deg, rgba(255, 255, 255, 0.1) -1.52%, rgba(255, 255, 255, 0.024) 104.35%);
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 120px;
    margin-top: 50px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        margin-top: 50px;
        margin-bottom: 80px;
    };
`
const WallTitle = styled.div`
    font-family: 'Poppins';
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 40px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        font-size: 16px;
        line-height: 20px;
    };
`
const WallDes = styled.div`
    font-family: 'Poppins';
    font-size: 22px;
    text-align: center;
    text-transform: uppercase;
    line-height: 40px;
    opacity: .9;
    margin-bottom: 30px;
    margin-top: 10px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        font-size: 14px;
        line-height: 20px;
        margin-top: 0px;
    };
`
const WallList = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        display: block;
        margin-bottom: 0px;
    };
`
const WallOnt = styled.div`
    width: 20%;
    border: 1px solid #C004DE;
    background: linear-gradient(0deg,rgba(0,0,0,0.2),rgba(0,0,0,0.2)),linear-gradient(90deg,rgba(255,255,255,0.1) -1.52%,rgba(255,255,255,0.024) 104.35%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 12px;
    padding: 30px;
    text-align: center;
    img{
        height: 50px;
    }
    span{
        display: block;
        font-family: 'Poppins';
        font-size: 24px;
        opacity: .9;
        margin-top: 20px;
    }
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        width: 100%;
        padding: 20px;
        margin-bottom: 10px;
        span{
            font-size: 16px;
            margin-top: 10px;
        }
    };
`
const WallBXHImg = styled.div`
    position: absolute;
    bottom: -8px;
    width: 200px;
    left: -130px;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        width: 100px;
        left: -50px;
    };
`
const PopConter = styled.div`
    position: fixed;
    width: 1000px;
    height: 560px;
    top: 50%;
    left: 50%;
    margin-left: -500px;
    margin-top: -280px;
    z-index: 99999;
    cursor: pointer;
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        display: block;
        width: 94%;
        top: 20%;
        left: 3%;
        margin-left: 0px;
        margin-top: 0px;
    }
`
const PopClose = styled.div`
    position: absolute; 
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    border: 2px solid #C004DE;
    &:before {
        float: right;
        position: relative;
        right: 11px;
        top: 4px;
        content: ' ';
        height: 20px;
        width: 2px;
        background-color: #C004DE;
        transform: rotate(45deg);
    };
    &:after {
        float: right;
        position: relative;
        right: 11px;
        top: 4px;
        content: ' ';
        height: 20px;
        width: 2px;
        background-color: #C004DE;
        transform: rotate(-45deg);
    };
`
const TvlConter = styled.div`
    display: flex;
    font-family: 'Inter';
    font-weight: 600;
    margin-top: 3%;
    div{
        padding: 5px 25px;
        background: rgba(192, 4, 222, .5);
        margin-right: 20px;
        border-radius: 10px
    }
    span{
        display: block;
    }
    em{
        font-style: inherit;
        opacity: .8;
    }
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        div{
            padding: 5px 15px;
        }
        span{
            font-size: 12px;
        }
        em{
            font-size: 12px;
        }
    }
`
const OurConter = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 80px;
`
const OurPro = styled.div`
    width: 25%;
    span{
        display: block;
        color: #C004DE;
        text-align: center;
        font-family: 'Inter';
        font-weight: 600;
        font-size: 16px;
    }
`
const OurImg = styled.img`
`
const AboutLent = styled.div`
    span{
        display: block;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
    }
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        span{
            font-size: 14px;
        }
    }
`
const Provides = styled.div`
    display: flex;
    position: relative;
    span{
        padding-left: 50px;
    }
`
const SingleLeft = styled.div`
    margin-top: 5px;
    img{
        position: absolute;
        width: 30px;
        z-index: 999;
    }
`
const SingleDashed = styled.div`
    position: absolute;
    top: 0px;
    margin-left: 14px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, #C004DE 0%, #C004DE 80%, transparent 50%);
    background-size: 1px 18px;
    background-repeat: repeat-y;
    margin-top: 5px;
`
const FontConter = styled.div`
    display: flex;
    position: relative;
`
const FontLeft = styled.div`
    display: flex;
    width: 65%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90deg, rgba(255, 255, 255, 0.1) -1.52%, rgba(255, 255, 255, 0.024) 104.35%);
    box-shadow: 0px 4px 40px rgb(0 0 0 / 5%);
    border-radius: 16px;
    padding: 20px;
    margin-top: 5%;
    img{
        width: 30px;
        height: 30px;
        margin-top: 5px;
    }
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        width: 100%;
    }
`
const FontRight = styled.div`
    width: 30%;
    margin-left: 5%; 
    div{
        position: absolute;
        background: linear-gradient(153.06deg, #5C1594 -0.31%, #210A5C 99.69%);
        border-radius: 24px;
        height: 100%;
        width: 30%;
    }
    img{
        position: absolute;
        width: 45%;
        margin-left: -60px;
        z-index: 999;
    }
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        position: absolute;
        right: -20px;
        bottom: 40%;
        margin-left: 0px;
        width: 50%;
        div{
            background: none;
        }
        img{
            margin-left: 0px;
            width: 100%;
        }
    }
`
const FontSize = styled.div`
    margin-left: 15px;
    span{
        display: block;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        padding-bottom: 20px;
        letter-spacing: 1px;
        opacity: .9;
    }
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        span{
            font-size: 14px;
        }
    }
`
const FramRight = styled.div`
    position: absolute;
    width: 40%;
    background: linear-gradient(153.06deg, #5C1594 -0.31%, #210A5C 99.69%);
    border-radius: 24px;
    height: 100%;
    img{
        position: absolute;
        bottom: -20px;
        left: -20px;
    }
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        background: none;
    }
`
const FramLeft = styled.div`
    display: flex;
    width: 55%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90deg, rgba(255, 255, 255, 0.1) -1.52%, rgba(255, 255, 255, 0.024) 104.35%);
    box-shadow: 0px 4px 40px rgb(0 0 0 / 5%);
    border-radius: 16px;
    padding: 20px;
    margin-left: 45%;
    img{
        width: 30px;
        height: 30px;
        margin-top: 5px;
    }
    ${({ theme }) => theme.mediaMaxQueries.xs} {
        width: 100%;
        margin-left: 0px;
    }
`
const FramChain = styled.div`
    display: inline-block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90deg, rgba(255, 255, 255, 0.1) -1.52%, rgba(255, 255, 255, 0.024) 104.35%);
    border: 0.5px solid #535353;
    backdrop-filter: blur(16px);
    padding: 5px 15px;
    margin-bottom: 30px;
`
const FramBottom = styled.div`
    display: table;
    font-family: 'Inter';
    font-style: normal;
    background: linear-gradient(54.3deg, #BC21BF -6.5%, #6A27D7 99.93%);
    border-radius: 12px;
    font-size: 16px;
    padding: 5px 20px;
    cursor: pointer;
    margin-bottom: 30px;
`


export default withTranslation()(Home);
