import React from 'react'
import { ThemeContextProvider } from './ThemeContext'
import { LoadingContextProvider } from './context/loading'

const Providers: React.FC = ({ children }) => {
  return (
    <ThemeContextProvider>
      <LoadingContextProvider>
        {children}
      </LoadingContextProvider>
    </ThemeContextProvider>
  )
}

export default Providers
