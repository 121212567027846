import React, { Suspense, Component } from 'react';
import styled from 'styled-components'
import { Switch, HashRouter, Route} from "react-router-dom";
import './App.less'

import Store from "./sotres";
import { withTranslation } from 'react-i18next'
import DocumentTitle from 'react-document-title'

import './i18n';
import ScrollToTop from './components/scrollToTop';
import Footer from './components/footer';
import Nav from './components/nav';
import Home from './components/home';

const emitter = Store.emitter
const dispatcher = Store.dispatcher
const store = Store.store

class App extends Component {
  state = {
    
  }
  
  render() {
    const {t} = this.props;
    return (
      <Suspense fallback={null}>
        <HashRouter>
        <Root>
          <ScrollToTop>
            <Nav/>
            <Switch>
              <Route path="/">
                <DocumentTitle title={t('title_hometitle')}/>
                <Home/>
              </Route>
            </Switch>
            <Footer/>
          </ScrollToTop>
        </Root>
        </HashRouter>
      </Suspense>
    );
  }
}

const Root = styled.div`
position: relative;
min-height: 100vh;
`

// export default App;
export default withTranslation()(App);
